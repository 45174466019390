  /* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


        if ($('.menu-shop-submenu-container').length > 0 || $('.menu-shop-submenu-en-container').length > 0 || $('.menu-shop-submenu-de-container').length > 0) {
          $('.magic-bump').remove();
          $(function() {
            $('a[href*=#]:not([href=#])').click(function() {
              if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                if (target.length) {
                  $('html,body').animate({
                    scrollTop: target.offset().top
                  }, 1000);
                  return false;
                }
              }
            });
          });
        }



        if ($('#block-collectie-overview--collection---slider').length >0) {
          $('#block-collectie-overview--collection---slider').slick(
            {
              slidesToShow: 6,
              slidesToScroll: 1,
              arrows: false,
              dots: false,
              responsive: [
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '65px',
                    arrows: true,
                    dots: false,
                  }
                }
              ]
            }
          );

          // $('#block-collectie-overview--collection---slider .bcocs-watch').on('mouseout', function(){
          //   $('#block-collectie-overview--collection---slider .bcocs-watch').removeClass('blurred active');
          // })

          // $('#block-collectie-overview--collection---slider .bcocs-watch').on('mouseover', function(){
          //   $('#block-collectie-overview--collection---slider .bcocs-watch').addClass('blurred');
          //   $(this).addClass('active');
          //   $(this).removeClass('blurred');
          // })
        }













        $(".field-date").datetimepicker({
          format: 'DD/MM/YYYY'
        });

        $(document).on("click", ".shop-tips", function() {
          // console.log("shop tips clicked!");
          var type = $(this).data('type');
          // $('body').css('overflow-y', 'hidden');
          $('body').addClass('locked');
          $('.shop-overlay.'+type).addClass('active');
        });

        // language selector, if language box clicked show other language options
        $(document).click(function(e) {
          if ($(e.target).closest("#lang_sel").length === 0) {
              $(".lang-sel__option-container").css("display", "none");
          } else {
            $(".lang-sel__option-container").css("display", "block");
          }
        });

        // Mobile language selector, check if this is the language picks, opacity is 1
        // *** Note: conditions will not work with local or dev stage because the conditions is specific to live site
        function mobile_language() {
          var pathname = window.location.pathname;
          if (window.location.href.indexOf("www.vandergangwatches.nl/en") > -1) {
            $(".icl-mobile-en").css("opacity", 1);
            console.log('en');
          } else if (window.location.href.indexOf("www.vandergangwatches.nl/de") > -1) {
            $(".icl-mobile-de").css("opacity", 1);
            console.log('de');
          } else {
            $(".icl-mobile-nl").css("opacity", 1);
            console.log('nl');
          }
        } mobile_language();



        // FOR WATCH & BAND SHOP FLOW TIPS OVERLAY
        // $(document).on("click", ".tips.lengte", function() {
        //   $('body').css('overflow-y', 'hidden');
        //   $('.shop-overlay.lengte').addClass('active');
        // });

        // $(document).on("click", ".tips.uitvoering", function() {
        //   $('body').css('overflow-y', 'hidden');
        //   $('.shop-overlay.uitvoering').addClass('active');
        // });

        // $(document).on("click", ".tips.stiksel", function() {
        //   $('body').css('overflow-y', 'hidden');
        //   $('.shop-overlay.stiksel').addClass('active');
        // });

        // $(document).on("click", ".tips.materiaal", function() {
        //   $('body').css('overflow-y', 'hidden');
        //   $('.shop-overlay.materiaal').addClass('active');
        // });

        // $(document).on("click", ".tips.algemene", function() {
        //   $('body').css('overflow-y', 'hidden');
        //   $('.shop-overlay.algemene').addClass('active');
        // });

        $(document).on("click", ".shop-overlay-close", function() {
          // $('body').css('overflow-y', 'initial');
          $('body').removeClass('locked');
          $('.shop-overlay').removeClass('active');
        });

        // FOR COLLECTIE MAGNIFIER SLIDER
        $(".fancybox").fancybox({
          padding    : 0,
          margin     : 10,
          nextEffect : 'fade',
          prevEffect : 'none',
          autoCenter : false,
          loop: false,
          afterLoad  : function () {
              $.extend(this, {
                  aspectRatio : false,
                  type    : 'html',
                  width   : '100%',
                  height  : '100%',
                  content : '<div class="fancybox-image with-zoom" style="background-image:url(' + this.href + '); background-size: contain; background-position:50% 50%;background-repeat:no-repeat;height:100%;width:100%;"></div><div class="buttons"><button style="position: absolute; top: 20px; z-index:99999;" id="btn-zoom-in" class="zoom-in">inzoomen</button><button style="position: absolute; top: 60px; z-index:99999;" id="btn-zoom-out" class="zoom-out">uitzoomen</button></div>'
              });
              $('.collectie-magnifier-thumbnails').css("position", "fixed");
          },
          afterShow : function() {
              const $section = document.getElementsByClassName("fancybox-image")[0];
              const panzoom = new Panzoom($section, {
                minScale: 1
              });

              const zoomInButton = document.getElementById("btn-zoom-in");
              const zoomOutButton = document.getElementById("btn-zoom-out");
              zoomInButton.addEventListener('click', panzoom.zoomIn);
              zoomOutButton.addEventListener('click', panzoom.zoomOut);

              $('.collectie-magnifier-thumbnail').on('click', function(){
                if($('.fancybox-overlay').length > 0) {
                  var imageSrc = $(this).children('img').attr('data-large');

                  $('.fancybox-overlay').find('.fancybox-image.with-zoom').css("background-image", "url("+imageSrc+")");
                }
              });
          },
          afterClose : function() {
            $('.collectie-magnifier-thumbnails').css("position", "absolute");
          }
      });

        function zoomOutMobile() {
          var viewport = document.querySelector('meta[name="viewport"]');

          if ( viewport ) {
            viewport.content = "initial-scale=1";
            viewport.content = "width=device-width";
          }
        }

        $('.fancybox-xs').fancybox({
          padding    : 0,
          margin     : 10,
          nextEffect : 'fade',
          prevEffect : 'none',
          autoCenter : false,
          loop: false,
          beforeShow: function(){
           $("body").css({'overflow-y':'hidden'});
          },
          afterClose: function(){
            zoomOutMobile();
           $("body").css({'overflow-y':'visible'});
          }
        });

        $('.collectie-magnifier-images').slick({
          speed: 500,
          arrows: false,
          dots: false,
          draggable: false
        });

        $('.collectie-magnifier-large-slides').slick({
          speed: 500,
          arrows: false,
          dots: false,
          draggable: false
        });

        $('.collectie-magnifier-thumbnails').slick({
          slidesToShow: 4,
          speed: 500,
          centerMode: false,
          focusOnSelect: false
        });

        $('.collectie-magnifier-thumbnail[data-slick-index="0"]').addClass('active');

        $('.collectie-magnifier-thumbnail').on('click', function() {
          $('.collectie-magnifier-thumbnail').removeClass('active');
          $(this).addClass('active');

          var sIdx = $(this).data('slick-index');
          $('.collectie-magnifier-images').slick('slickGoTo', parseInt(sIdx));
          $('.collectie-magnifier-large-slides').slick('slickGoTo', parseInt(sIdx));
        });

        $('.collectie-magnifier-large-close').on('click', function(){
          $('.collectie-magnifier-large').removeClass('active');
        });

        // RADIO BUTTON ON CONTACT FORM
        $('input[name=method]').on('change', function() {
          var method = $('input[name=method]:checked').val();
          if (method === 'email') {
            $('.contact-form-email').show();
            $('.contact-form-standard').hide();
          } else {
            $('.contact-form-email').hide();
            $('.contact-form-standard').show();
          }
        });

        var tag = document.createElement('script');

        tag.src = "https://www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        var curlang = $('html').attr('lang');
        var form;
        if (curlang === 'en-US') {

          form = $('.mc4wp-form');
          form.find('[name="EMAIL"]').attr('placeholder', 'Enter your email address');
          form.find('button').text('Subscribe');

        }

        if (curlang === 'de-DE') {

          form = $('.mc4wp-form');
          form.find('[name="EMAIL"]').attr('placeholder', 'Geben Sie Ihre E-Mail-Adresse ein');
          form.find('button').text('Anmelden');

        }

        var repositionSlickDots = function(_this){
          setTimeout(function(){
            var mobTopPos = _this.find('.slick-current').find('.block-slide-image-mobile').height();

            _this.children('.slick-dots').css({
              'top': mobTopPos+13+'px'
            });
          }, 200);

        };

        var respositionSlickAfterChange = function(_this, slideNum){
          var mobTopPos = _this.find('.block-slide').eq(slideNum).find('.block-slide-image-mobile').height();

          _this.children('.slick-dots').css({
            'top': mobTopPos+13+'px'
          });
        };

        $('.collectie-header-slides').each(function(){
          var collectieHeaderSlidesAutoPlay = $(this).attr('data-value');

          $(this).on('init', function(event, slick, currentSlide, slide){

              var activeSlide = $(this).find('.block-slide').eq(0);

              if(activeSlide.hasClass('theme-dark')){
                $(this).find('.slick-dots').addClass('theme-dark');
              }
              else if(activeSlide.hasClass('theme-light')) {
                $(this).find('.slick-dots').addClass('theme-light');
              }

          });

          if(collectieHeaderSlidesAutoPlay === 'true') {
            $(this).not('.slick-initialized').slick({
              fade: true,
              dots: true,
              adaptiveHeight: true,
              autoplay: true,
              autoplaySpeed: 8000,
            }).on('beforeChange', function(event, slick, currentSlide, nextSlide, slide){

              var activeSlide = $(this).find('.block-slide').eq(nextSlide);

              $(this).find('.slick-dots').removeClass('theme-light theme-dark');

              if(activeSlide.hasClass('theme-dark')){
                $(this).find('.slick-dots').addClass('theme-dark');
              }
              else if(activeSlide.hasClass('theme-light')) {
                $(this).find('.slick-dots').addClass('theme-light');
              }

            });
          }
          else {
            $(this).not('.slick-initialized').slick({
              fade: true,
              dots: true,
              adaptiveHeight: true,
              autoplay: false,
            }).on('beforeChange', function(event, slick, currentSlide, nextSlide, slide){

              var activeSlide = $(this).find('.block-slide').eq(nextSlide);

              $(this).find('.slick-dots').removeClass('theme-light theme-dark');

              if(activeSlide.hasClass('theme-dark')){
                $(this).find('.slick-dots').addClass('theme-dark');
              }
              else if(activeSlide.hasClass('theme-light')) {
                $(this).find('.slick-dots').addClass('theme-light');
              }

            });
          }
        });

        $('.manufactuur-slides').each(function(){
          var manufactuurSlidesAutoPlay = $(this).attr('data-value');

          $(this).on('init', function(event, slick, currentSlide, slide){

            var activeSlide = $(this).find('.block-slide').eq(0);

            if(activeSlide.hasClass('theme-dark')){
              $(this).find('.slick-dots').addClass('theme-dark');
            }
            else if(activeSlide.hasClass('theme-light')) {
              $(this).find('.slick-dots').addClass('theme-light');
            }

          });

          if(manufactuurSlidesAutoPlay === 'true') {
            $(this).not('.slick-initialized').slick({
              fade: true,
              dots: true,
              autoplay: true,
              autoplaySpeed: 8000,
            }).on('beforeChange', function(event, slick, currentSlide, nextSlide, slide){

              var activeSlide = $(this).find('.block-slide').eq(nextSlide);

              $(this).find('.slick-dots').removeClass('theme-light theme-dark');

              if(activeSlide.hasClass('theme-dark')){
                $(this).find('.slick-dots').addClass('theme-dark');
              }
              else if(activeSlide.hasClass('theme-light')) {
                $(this).find('.slick-dots').addClass('theme-light');
              }

            });
          }
          else {
            $(this).not('.slick-initialized').slick({
              fade: true,
              dots: true,
              autoplay: false,
            }).on('beforeChange', function(event, slick, currentSlide, nextSlide, slide){

              var activeSlide = $(this).find('.block-slide').eq(nextSlide);

              $(this).find('.slick-dots').removeClass('theme-light theme-dark');

              if(activeSlide.hasClass('theme-dark')){
                $(this).find('.slick-dots').addClass('theme-dark');
              }
              else if(activeSlide.hasClass('theme-light')) {
                $(this).find('.slick-dots').addClass('theme-light');
              }

            });
          }
        });

        // COLLECTIE DETAIL - HIDE ARROWS IF ONLY IT'S SINGLE SLIDE
        $('body.single-cpt_watch .home-slides').each(function(){
          var count = 0;
          console.log($(this).children('.slick-slide').length);
          $(this).children('.slick-slide').each(function(){
            count++;
          });
          console.log('count = ' + count);

          if (count <= 0) {
            $(this).find('.nav-main-wrapper').hide();
          }
        });

        var dotsPosTop;
        var dotsPosLeft;
        var windowW = $(window).width();
        $('.collectie-slides').each(function(){
          var _this = $(this);

          if(windowW >= 993){
            dotsPosTop = $(this).find('.nav-main-wrapper.hide-mobile').position().top;
            dotsPosLeft = $(this).find('.nav-main-wrapper.hide-mobile').position().left + 25;
          }
          else if(windowW <= 992) {
            dotsPosTop = $(this).find('.nav-main-wrapper.hide-desktop').position().top;
            dotsPosLeft = $(this).find('.nav-main-wrapper.hide-desktop').position().left + 25;
          }

          $(this).on('init', function(event, slick, currentSlide, slide){

              var activeSlide = $(this).find('.block-slide').eq(0);

              if(activeSlide.hasClass('theme-dark')){
                $(this).find('.slick-dots').addClass('theme-dark');
              }
              else if(activeSlide.hasClass('theme-light')) {
                $(this).find('.slick-dots').addClass('theme-light');
              }

          });

          $(this).not('.slick-initialized').slick({
            fade: true,
            dots: true,
            arrows: true
          }).on('afterChange', function(event, slick, currentSlide, nextSlide){
              setTimeout(function(){
                $('.collectie-slides').each(function(){
                  if(windowW >= 993){
                    dotsPosTop = $(this).find('.nav-main-wrapper.hide-mobile').position().top;
                    dotsPosLeft = $(this).find('.nav-main-wrapper.hide-mobile').position().left + 25;
                  }
                  else if(windowW <= 992) {
                    dotsPosTop = $(this).find('.nav-main-wrapper.hide-desktop').position().top;
                    dotsPosLeft = $(this).find('.nav-main-wrapper.hide-desktop').position().left + 25;
                  }
                  if($(this).hasClass('s-image-right')){
                    $(this).children('.slick-dots').css({
                      'top': dotsPosTop+'px',
                      'left': dotsPosLeft+'px'
                    });
                  }
                  else {
                    $(this).children('.slick-dots').css({
                      'top': dotsPosTop+'px',
                      'right': dotsPosLeft+'px'
                    });
                  }
                });
              }, 100);

              var activeSlide = $(this).find('.block-slide').eq(nextSlide);

              $(this).find('.slick-dots').removeClass('theme-light theme-dark');

              if(activeSlide.hasClass('theme-dark')){
                $(this).find('.slick-dots').addClass('theme-dark');
              }
              else if(activeSlide.hasClass('theme-light')) {
                $(this).find('.slick-dots').addClass('theme-light');
              }
          });

          var leftArrow = $(this).find('.slick-prev');
          var rightArrow = $(this).find('.slick-next');
          var navContainer = $(this).find('.slick-dots').offset();
          var mainWidth = $(this).parents('.flex-col').width();
          var dotDirection;

            $(this).find('.left-nav').on('click', function(){
              leftArrow.click();
            });

            $(this).find('.right-nav').on('click', function(){
              rightArrow.click();
            });

            var cloneDots = $(this).find('.slick-dots').clone(true,true);

            $(this).find('.dots-container').append(cloneDots);

            if(windowW >= 993){
              dotsPosTop = $(this).find('.nav-main-wrapper.hide-mobile').position().top;
              dotsPosLeft = $(this).find('.nav-main-wrapper.hide-mobile').position().left + 25;
            }
            else if(windowW <= 992) {
              dotsPosTop = $(this).find('.nav-main-wrapper.hide-desktop').position().top;
              dotsPosLeft = $(this).find('.nav-main-wrapper.hide-desktop').position().left + 25;
            }

            if($(this).hasClass('s-image-right')){
              $(this).children('.slick-dots').css({
                'top': dotsPosTop+'px',
                'left': dotsPosLeft+'px'
              });
            }
            else {
              $(this).children('.slick-dots').css({
                'top': dotsPosTop+'px',
                'right': dotsPosLeft+'px'
              });
            }

        });

          $(window).resize(function() {
            windowW = $(window).width();

            setTimeout(function(){
              $('.collectie-slides').each(function(){
                if(windowW >= 993){
                  dotsPosTop = $(this).find('.nav-main-wrapper.hide-mobile').position().top;
                  dotsPosLeft = $(this).find('.nav-main-wrapper.hide-mobile').position().left + 25;
                }

                if(windowW <= 992) {
                  dotsPosTop = $(this).find('.nav-main-wrapper.hide-desktop').position().top;
                  dotsPosLeft = $(this).find('.nav-main-wrapper.hide-desktop').position().left + 25;
                }

                if($(this).hasClass('s-image-right')){
                  $(this).children('.slick-dots').css({
                    'top': dotsPosTop+'px',
                    'left': dotsPosLeft+'px'
                  });
                }
                else {
                  $(this).children('.slick-dots').css({
                    'top': dotsPosTop+'px',
                    'right': dotsPosLeft+'px'
                  });
                }
              });
            }, 100);

          if($('.no-specific-position').length > 0){
            $('.no-specific-position').each(function(){
              var mobTopPos = $(this).find('.block-slide-image-mobile').height();
              $(this).children('.slick-dots').css({
                'top': mobTopPos+20+'px'
              });
            });
          }
        });

        $('.field-plaats').appendTo('.field-postcode-wrap');
        $('.field-time').appendTo('.field-date-wrap');

        // TOGGLE TO OPEN MOBILE NAV
        $('.nav-mobile-trigger').on('click', function(){
          $('.nav-mobile-mask').addClass('active');
          $('.nav-mobile-container').addClass('active');
          $('.nav-mobile-wrapper').addClass('active');
          $('body, html').addClass('menu-active');
        });

        // TOGGLE TO CLOSE MOBILE NAV
        $('.nav-mobile-close').on('click', function(){
          $('.nav-mobile-mask').removeClass('active');
          $('.nav-mobile-container').removeClass('active');
          $('.nav-mobile-wrapper').removeClass('active');
          $('body, html').removeClass('menu-active');
        });

        $('.menu-sub-navi-list').on('click', function(){
          $(this).toggleClass('active');
          $('#menu-sub-navi').toggleClass('active');
        });

        $('.menu-item').on('click', function(){
          $(this).siblings('.current-menu-item').removeClass('current-menu-item current_page_item current-menu-ancestor current-menu-parent current_page_parent current_page_ancestor');
        });

        if($('.current-menu-item').length > 0) {
          $('.current-menu-item').parents('.menu-item').addClass('current-menu-parent');
        }

        //FLUID MAGIC BUMP EFFECT
        var navBarActiveChild = $('#menu-sub-navi').find('li.current-menu-item');
        var navBarActiveChildExist = navBarActiveChild.length;
        var navBarActiveParent = $('#menu-sub-navi').find('li.current-menu-parent');
        var navBarActiveParentExist = navBarActiveParent.length;
        var activeMenuWidth;
        var activeMenuOffset;
        var magicBumpAnim = function(activeChild){
              activeMenuWidth = activeChild.width();
              activeMenuOffset = activeChild.position().left;
              $('.magic-bump').width(activeMenuWidth);
              $('.magic-bump').css({'left': activeMenuOffset+'px', 'display': 'block'});
              $(window).resize(function(){
                activeMenuWidth = activeChild.width();
                activeMenuOffset = activeChild.position().left;
                $('.magic-bump').width(activeMenuWidth);
                $('.magic-bump').css('left', activeMenuOffset+'px');
              });
        };
        if(navBarActiveParentExist) {
          console.log('parent magic');
          magicBumpAnim(navBarActiveParent);
          $('.nav-secondary .menu-sub-navi-container ul li.current-menu-item').removeClass('current-menu-item');
          $('.nav-secondary .menu-sub-navi-container ul li.current-menu-parent').addClass('current-menu-item');
        } else if(navBarActiveChildExist){
          console.log('child magic');
          magicBumpAnim(navBarActiveChild);
        }

      if(windowW >= 993){
        $('#menu-sub-navi').find('li').mouseenter(function(){
          var listW = $(this).width();
          var listLeftPos = $(this).position().left;
          var balancePos = listLeftPos - activeMenuOffset;
          $('.magic-bump').stop().animate({'width': listW+'px', 'left': listLeftPos+'px' });
        }).mouseleave(function(){
          if(!$('#menu-sub-navi').is(':hover')){
            $('.magic-bump').stop().animate({'width': activeMenuWidth+'px', 'left': activeMenuOffset+'px' });
          }
        });
        $('#menu-sub-navi').mouseleave(function(){
          $('.magic-bump').stop().animate({'width': activeMenuWidth+'px', 'left': activeMenuOffset+'px' });
        });
      }

        /*$(window).resize(
        function () {
          var wW = $(window).width();
          console.log(wW);

          if (wW < 1300) {
            var dlPos = 430 - ((1300 - wW) / 2); // dots position
            var pblPos = 355 - ((1300 - wW) / 2); // previous button position
            var nblPos = 490 - ((1300 - wW) / 2); // next button position

            $('.collectie-slides-desktop .slick-dots').css('left', dlPos);
            $('.collectie-slides-desktop .slick-prev').attr('style', 'left: ' + pblPos + 'px !important');
            $('.collectie-slides-desktop .slick-next').attr('style', 'left: ' + nblPos + 'px !important');

            // for right aligned variant
            dlPos = 430 - ((1300 - wW) / 2) + 440; // dots position
            pblPos = 355 - ((1300 - wW) / 2) + 440; // previous button position
            nblPos = 490 - ((1300 - wW) / 2) + 440; // next button position

            $('.collectie-slides-desktop-right .slick-dots').css('left', dlPos);
            $('.collectie-slides-desktop-right .slick-prev').attr('style', 'left: ' + pblPos + 'px !important');
            $('.collectie-slides-desktop-right .slick-next').attr('style', 'left: ' + nblPos + 'px !important');
          } else {
            $('.collectie-slides-desktop .slick-dots').css('left', 430);
            $('.collectie-slides-desktop .slick-prev').attr('style', 'left: 355px !important');
            $('.collectie-slides-desktop .slick-next').attr('style', 'left: 490px !important');

            $('.collectie-slides-desktop-right .slick-dots').css('left', 870);
            $('.collectie-slides-desktop-right .slick-prev').attr('style', 'left: 795px !important');
            $('.collectie-slides-desktop-right .slick-next').attr('style', 'left: 930px !important');
          }
        });*/

        // $('.alm-load-more-btn').click(function(){
        //   $('.spinner').css('display', 'block');
        //   $(this).css('text-indent', '-9999');
        // });

        $('.alm-load-more-btn').addClass('icon icon-right arrow-right-white');

        // Custom sorting plugin
        (function($) {
          $.fn.sorted = function(customOptions) {
            var options = {
              reversed: false,
              by: function(a) { return a.text(); }
            };
            $.extend(options, customOptions);
            $data = $(this);
            arr = $data.get();
            arr.sort(function(a, b) {
              var valA = options.by($(a));
              var valB = options.by($(b));
              if (options.reversed) {
                return (valA < valB) ? 1 : (valA > valB) ? -1 : 0;
              } else {
                return (valA < valB) ? -1 : (valA > valB) ? 1 : 0;
              }
            });
            return $(arr);
          };
        })(jQuery);

        if($(window).width() <= 767) {
          boxPerRow = 2;
        }
        else {
          boxPerRow = 3;
        }

        var wW = $('.watch-group').width();
        var bW = 0;
        var activeCount = 0;

        var nbH = 0;

        function fSetBoxSize() {
          bW = wW / boxPerRow;
          var bH = bW - 40;
          if($(window).width() <= 767) {
            bH = bW;
          }
          else {
            bH = bW - 40;
          }
          nbH = bH + 20;
          var watchWidth = 300;

          $('.watch.active').css('width', bW+'px');
          $('.watch.active').css('height', bH+'px');

          $('.watch.active').css('padding-left', ((bW - watchWidth) / 2) + 'px');
          $('.watch.active').css('padding-right', ((bW - watchWidth) / 2) + 'px');
        }

        function fSetStageHeight() {
          var sH = Math.ceil($('.watch.active').length / boxPerRow) * nbH;
          $('.watch-group').height(sH);
        }

        function fArrangeBox() {
          $('.watch.active').each(function(index){

            var X = bW * (index % boxPerRow);
            var X2 = bW * (index % boxPerRow) + (wW * 0.015);
            var X3 = bW * (index % boxPerRow) + (wW * 0.03);
            var rNum = Math.floor(index / boxPerRow);
            var Y = rNum * nbH; //bW

            if(index % 3 === 0){
              $(this).css('transform', 'translate3d(' + X + 'px,' + Y + 'px, 0px)');
            }
            else if(index % 3 === 1){
              $(this).css('transform', 'translate3d(' + X2 + 'px,' + Y + 'px, 0px)');
            }
            else if(index % 3 === 2){
              $(this).css('transform', 'translate3d(' + X3 + 'px,' + Y + 'px, 0px)');
            }

          });
        }

        fSetBoxSize();
        fSetStageHeight();
        fArrangeBox();
        $('.switch').on('click', function(){
          var tags = [];

          // GET THE TAG GROUP
          var tagGroup = $(this).data('taggroup');

          // CHECK IF ANY OF THE SWITCH IN THIS TAG GROUP IS ACTIVE
          var hasActive = $('.switch.'+tagGroup).hasClass('active');

          console.log($('.switch.'+tagGroup).hasClass('active'));

          //
          if($(this).parents('.dropdown')){
            var buttonText = $(this).text();
            $(this).parents('.dropdown-menu').siblings('button').children('.text').text(buttonText);
          }

          // IF ONE OF THE SWITCH IN THIS TAG GROUP IS ACTIVE
          if (hasActive) {
            if ($(this).hasClass('active')) {
              $(this).toggleClass('active');
            } else {
              $('.switch.'+tagGroup).removeClass('active');
              $(this).toggleClass('active');
            }
          } else {
            $(this).toggleClass('active');
          }

          // CHECK IF ANY SWITCH IS ACTIVE
          var switchMaterial = $('.switch').hasClass('active');

          // ONE OF THE SWITCH IS ACTIVE
          if (switchMaterial) {
            $('.switch.active').each(function(){
              tags.push($(this).data('tag'));
            });

            var target = '.watch';

            for (i=0; i < tags.length; i++) {
              target+='.'+tags[i];
            }

            // HIDE ALL BOXES
            $('.watch').removeClass('active');

            $(target).addClass('active');
          } else {
            // NO SWITCH IS ACTIVE, SO DISPLAY EVERYTHING
            $('.watch').addClass('active');
          }

          fArrangeBox();


        });

        $(window).resize(function(){
          wW = $('.watch-group').width();

          if($(window).width() <= 767) {
            boxPerRow = 2;
          }
          else {
            boxPerRow = 3;
          }

          fSetBoxSize();
          fSetStageHeight();
          fArrangeBox();
        });

        $(window).load(function(){
          $('.home-slides').each(function(){
            var homeSlidesAutoPlay = $(this).attr('data-value');
            $(this).on('init', function(event, slick, currentSlide, slide){
                _this = $(this);
                var activeCS = currentSlide;

                repositionSlickDots(_this);

                var activeSlide = $(this).find('.block-slide').eq(0);

                if(activeSlide.hasClass('theme-dark')){
                  $(this).find('.slick-dots').addClass('theme-dark');
                }
                else if(activeSlide.hasClass('theme-light')) {
                  $(this).find('.slick-dots').addClass('theme-light');
                }

                $(window).resize(function(){
                  repositionSlickDots(_this);
                });

            });

            if(homeSlidesAutoPlay === 'true') {
              $(this).not('.slick-initialized').slick({
                fade: true,
                dots: true,
                autoplay: true,
                autoplaySpeed: 8000,

              }).on('beforeChange', function(event, slick, currentSlide, nextSlide, slide){

                var activeSlide = $(this).find('.block-slide').eq(nextSlide);

                $(this).find('.slick-dots').removeClass('theme-light theme-dark');

                if(activeSlide.hasClass('theme-dark')){
                  $(this).find('.slick-dots').addClass('theme-dark');
                }
                else if(activeSlide.hasClass('theme-light')) {
                  $(this).find('.slick-dots').addClass('theme-light');
                }

              }).on('afterChange', function(event, slick, currentSlide, nextSlide, slide){
                _this = $(this);
                respositionSlickAfterChange(_this, currentSlide);

              });
            }
            else {
              $(this).not('.slick-initialized').slick({
                fade: true,
                dots: true,
                autoplay: false,
                responsive: [
                  {
                    breakpoint: 768,
                    settings: {
                      arrows: true,
                      dots: false,
                    }
                  }
                ]
              }).on('beforeChange', function(event, slick, currentSlide, nextSlide, slide){

                var activeSlide = $(this).find('.block-slide').eq(nextSlide);

                $(this).find('.slick-dots').removeClass('theme-light theme-dark');

                if(activeSlide.hasClass('theme-dark')){
                  $(this).find('.slick-dots').addClass('theme-dark');
                }
                else if(activeSlide.hasClass('theme-light')) {
                  $(this).find('.slick-dots').addClass('theme-light');
                }

              }).on('afterChange', function(event, slick, currentSlide, nextSlide, slide){
                _this = $(this);
                respositionSlickAfterChange(_this, currentSlide);

              });
            }
          });

          $('.slick-slider').each(function (){
            var dots = $(this).find('.slick-dots');
            var prev = $(this).find('.slick-prev');
            var next = $(this).find('.slick-next');

            prev.appendTo(dots);
            next.appendTo(dots);
          });

          if($('.no-specific-position').length > 0){
            $('.no-specific-position').each(function(){
                var mobTopPos = $(this).find('.block-slide-image-mobile').height();
                $(this).children('.slick-dots').css({
                  'top': mobTopPos+13+'px'
                });
            });
          }

        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Manufactuur
    'manufactuur': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Contact
    'contact': {
      init: function() {
        // JavaScript to be fired on the home page





      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'shop': {
      init: function() {

          $('li.icl-nl a').attr('href', '#');

          $('li.icl-nl a').click(function(e){
            e.preventDefault();
            var pageURL = window.location.href;
            var URLParams = pageURL.split('#');
            URLParams[1] = URLParams[1].replace('&lang=nl', '');
            URLParams[1] = URLParams[1].replace('&lang=en', '');
            URLParams[1] = URLParams[1].replace('&lang=de', '');
            URLParams[1] = URLParams[1].replace('?lang=nl', '');
            URLParams[1] = URLParams[1].replace('?lang=en', '');
            URLParams[1] = URLParams[1].replace('?lang=de', '');
            URLParams[1] = URLParams[1].replace('&lang=undefined', '');
            URLParams[0] = URLParams[0].replace('/en/', '/');
            URLParams[0] = URLParams[0].replace('/de/', '/');
            // console.log(URLParams[0] + '#' + URLParams[1] + '&lang=bm');
            if ( URLParams[1] .indexOf("?") !== "-1" ) {
              window.location.replace(URLParams[0] +  '#' + URLParams[1] + '&lang=nl');
            } else {
              window.location.replace(URLParams[0] +  '#' + URLParams[1] + '?lang=nl');
            }
          });

          $('li.icl-en a').attr('href', '#');

          $('li.icl-en a').click(function(e){
            e.preventDefault();
            var pageURL = window.location.href;
            var URLParams = pageURL.split('#');
            URLParams[1] = URLParams[1].replace('&lang=en', '');
            URLParams[1] = URLParams[1].replace('&lang=nl', '');
            URLParams[1] = URLParams[1].replace('&lang=de', '');
            URLParams[1] = URLParams[1].replace('?lang=nl', '');
            URLParams[1] = URLParams[1].replace('?lang=en', '');
            URLParams[1] = URLParams[1].replace('?lang=de', '');
            URLParams[1] = URLParams[1].replace('&lang=undefined', '');
            URLParams[0] = URLParams[0].replace('/de/', '/');
            URLParams[0] = URLParams[0].replace('/shop', '/en/shop');
            URLParams[0] = URLParams[0].replace('/banden', '/en/banden');

             // alert(URLParams[0] +  '#' + URLParams[1] + '&lang=en');
            if ( URLParams[1] .indexOf("?") !== "-1" ) {
              window.location.replace(URLParams[0] +  '#' + URLParams[1] + '&lang=en');
            } else {
              window.location.replace(URLParams[0] +  '#' + URLParams[1] + '?lang=en');
            }

          });

          $('li.icl-de a').attr('href', '#');

          $('li.icl-de a').click(function(e){
            e.preventDefault();
            var pageURL = window.location.href;
            var URLParams = pageURL.split('#');
            URLParams[1] = URLParams[1].replace('&lang=en', '');
            URLParams[1] = URLParams[1].replace('&lang=nl', '');
            URLParams[1] = URLParams[1].replace('&lang=de', '');
            URLParams[1] = URLParams[1].replace('?lang=nl', '');
            URLParams[1] = URLParams[1].replace('?lang=en', '');
            URLParams[1] = URLParams[1].replace('?lang=de', '');
            URLParams[1] = URLParams[1].replace('&lang=undefined', '');
            URLParams[0] = URLParams[0].replace('/en/', '/');
            URLParams[0] = URLParams[0].replace('/shop', '/de/shop');
            URLParams[0] = URLParams[0].replace('/banden', '/de/banden');

            // console.log(URLParams[0] +  '#' + URLParams[1] + '&lang=en');
            if ( URLParams[1] .indexOf("?") !== "-1" ) {
              window.location.replace(URLParams[0] +  '#' + URLParams[1] + '&lang=de');
            } else {
              window.location.replace(URLParams[0] +  '#' + URLParams[1] + '?lang=de');
            }

          });


      }
    },
    'banden': {
      init: function() {

          $('li.icl-nl a').attr('href', '#');

          $('li.icl-nl a').click(function(e){
            e.preventDefault();
            var pageURL = window.location.href;
            var URLParams = pageURL.split('#');
            URLParams[1] = URLParams[1].replace('&lang=nl', '');
            URLParams[1] = URLParams[1].replace('&lang=en', '');
            URLParams[1] = URLParams[1].replace('&lang=de', '');
            URLParams[1] = URLParams[1].replace('?lang=nl', '');
            URLParams[1] = URLParams[1].replace('?lang=en', '');
            URLParams[1] = URLParams[1].replace('?lang=de', '');
            URLParams[1] = URLParams[1].replace('&lang=undefined', '');
            URLParams[0] = URLParams[0].replace('/en/', '/');
            URLParams[0] = URLParams[0].replace('/de/', '/');
            // console.log(URLParams[0] + '#' + URLParams[1] + '&lang=bm');
            if ( URLParams[1] .indexOf("?") !== "-1" ) {
              window.location.replace(URLParams[0] +  '#' + URLParams[1] + '&lang=nl');
            } else {
              window.location.replace(URLParams[0] +  '#' + URLParams[1] + '?lang=nl');
            }
          });

          $('li.icl-en a').attr('href', '#');

          $('li.icl-en a').click(function(e){
            e.preventDefault();
            var pageURL = window.location.href;
            var URLParams = pageURL.split('#');
            URLParams[1] = URLParams[1].replace('&lang=en', '');
            URLParams[1] = URLParams[1].replace('&lang=nl', '');
            URLParams[1] = URLParams[1].replace('&lang=de', '');
            URLParams[1] = URLParams[1].replace('?lang=nl', '');
            URLParams[1] = URLParams[1].replace('?lang=en', '');
            URLParams[1] = URLParams[1].replace('?lang=de', '');
            URLParams[1] = URLParams[1].replace('&lang=undefined', '');
            URLParams[0] = URLParams[0].replace('/de/', '/');
            URLParams[0] = URLParams[0].replace('/shop', '/en/shop');
            URLParams[0] = URLParams[0].replace('/banden', '/en/banden');

             // alert(URLParams[0] +  '#' + URLParams[1] + '&lang=en');
            if ( URLParams[1] .indexOf("?") !== "-1" ) {
              window.location.replace(URLParams[0] +  '#' + URLParams[1] + '&lang=en');
            } else {
              window.location.replace(URLParams[0] +  '#' + URLParams[1] + '?lang=en');
            }

          });

          $('li.icl-de a').attr('href', '#');

          $('li.icl-de a').click(function(e){
            e.preventDefault();
            var pageURL = window.location.href;
            var URLParams = pageURL.split('#');
            URLParams[1] = URLParams[1].replace('&lang=en', '');
            URLParams[1] = URLParams[1].replace('&lang=nl', '');
            URLParams[1] = URLParams[1].replace('&lang=de', '');
            URLParams[1] = URLParams[1].replace('?lang=nl', '');
            URLParams[1] = URLParams[1].replace('?lang=en', '');
            URLParams[1] = URLParams[1].replace('?lang=de', '');
            URLParams[1] = URLParams[1].replace('&lang=undefined', '');
            URLParams[0] = URLParams[0].replace('/en/', '/');
            URLParams[0] = URLParams[0].replace('/shop', '/de/shop');
            URLParams[0] = URLParams[0].replace('/banden', '/de/banden');

            // console.log(URLParams[0] +  '#' + URLParams[1] + '&lang=en');
            if ( URLParams[1] .indexOf("?") !== "-1" ) {
              window.location.replace(URLParams[0] +  '#' + URLParams[1] + '&lang=de');
            } else {
              window.location.replace(URLParams[0] +  '#' + URLParams[1] + '?lang=de');
            }

          });


      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  var currentUrl = window.location.href;
  shopRedirect = currentUrl.indexOf("shop/%23");
  if (shopRedirect !== -1) {
      decondeurl = currentUrl.replace("shop/%23", "shop/#");
      window.location = decondeurl;
  }

})(jQuery); // Fully reference jQuery after this point.
